<template>
  <div>
    <v-row
      align="center"
      > 
      <v-col 
        class="pr-0"
        lg="1"
        cols="3">
        <v-img 
          class="rounded-lg" 
          width="60px" 
          height="60px" 
          v-if="item.product.images.length"
          :src="url + item.product.images[0].thumbUrl "/>
      </v-col>
      <v-col>
        <div
          class="text-subtitle-2"
          >
          {{ item.variant.name }}
        </div>

        <div>
          <template
            v-if="item.variant"
            v-for="(option, idx) in item.variant.variantOptionValues"
            >
            <span
              class="caption"
              >
              {{ option.optionValue.optionType.name }}: {{ option.optionValue.value }} {{ (idx + 1) != item.variant.variantOptionValues.length ? '|' : '' }}
            </span>
          </template>
        </div>

        <div>
          <span
            class="caption"
            >
            {{ item.variant.displayPrice }}
          </span>
        </div>
      </v-col>
      <v-col 
        class="px-0"
        cols="1"
        >
        <v-text-field
          min="1"
          v-model="item.quantity"
          type="number"
          @blur="asd(item, childToken)"
          >
        </v-text-field>
      </v-col>
      <v-col 
        class="text-right mr-md-0 mr-5"
        cols="1">
        <v-hover v-slot="{ hover }">
          <v-btn  
            @click.stop="removeItem(item, childToken)"
            icon  
            :color="hover ? 'red' : 'grey'"
            > 
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>

    <v-snackbar
      :timeout="2000"
      v-model="alert.active"
      :color="alert.type"
      >
      <div
        class="d-flex justify-center align-center"
        >
        <v-icon
          class="mr-1"
          >
          {{ alert.type == 'success' ? 'mdi-check' : '' }}
        </v-icon>{{ alert.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditItem, DeleteItem } from '@/graphql/mutations/order'

export default {
  name: 'cartProduct',
  data: () => ({
    quantity: 1,
    alert: {
      active: false,
      message: null,
      type: ''
    },
  }),

  props: {
    item:{
      type: Object, 
      required: true
    },
    orderToken: {
      type: String,
      required: true
    },
    childToken: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    removeItem (item, token) {
      this.$apollo.mutate({
        mutation: DeleteItem,
        variables: {
          input: {
            orderToken: this.orderToken,
            lineItemId: item.id
          }
        }
      }).then( res => {
        this.$emit('reload')
      })
    },

    asd (item, token) {
      this.$emit('loading')
      if (item.quantity == 0) item.quantity = 1
      if (item.quantity > item.totalStock) {
        this.alert.type = 'error'
        this.alert.message = `El máximo disponible es ${item.totalStock}.`
        this.alert.active = true
      } else {

        this.$apollo.mutate({
          mutation: EditItem,
          variables: {
            input: {
              lineItemId: item.id,
              orderToken: token,
              attributes: {
                quantity: item.quantity
              }
            }
          },
          fetchPolicy: 'no-cache'
        }).then( res => {
          this.$emit('reload')
          this.alert.type = 'success'
          this.alert.message = `¡Edición exitosa!`
          this.alert.active = true
        })
      }
    },
  }
}
</script>
